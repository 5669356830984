<template>
  <div>
    <v-row>
      <v-col lg="6">
        <s-crud
          title="Impresion de Etiquetas"
          @save="save($event)"
          :config="config"
          add
        >
          <template scope="props">
            <v-container v-if="props.item != null">
              <v-row>
                <v-col lg="6" class="s-col-form">
                  <s-select-supervice full label="Empacadora" :position="1" return-object v-model="objEmpaquetadora" >
                  </s-select-supervice>
                </v-col>
                <v-col lg="3" class="s-col-form" >
                  <s-select-turn label="Turno" v-model="props.item.TrnID">

                  </s-select-turn>
                  <!-- <s-select-definition label="Turno" :def="1278"></s-select-definition> -->
                </v-col>
                <v-col lg="3" class="s-col-form" >
                  <s-text number label="Cantidad" v-model="props.item.Quantity"></s-text>
                </v-col>
                
              </v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import _sPrintLaber from "@/services/FreshProduction/PrfPrintLabelService";
import SSelectSupervice from '../../../components/HarvestSchedule/SSelectSupervice.vue';
import sSelectTurn from '@/components/FreshProduction/Turn/sSelectTurn';
export default {
  components: {SSelectSupervice, sSelectTurn},
  data: () => ({
    objEmpaquetadora:null,
    config: {
      model: {
        PrlID: "ID",
      },
      service: _sPrintLaber,
      headers: [
        { text: "Codigo", value: "PrlID" },
        {text:"Turno",value:"TrnDescription"},
        {text:"Empacadora", value:"Packer"},
        // {text:"", value:""},
        // {text:"", value}
        ],
    },
    
  }),
  methods: {
    Initialize() {},
    save(item){
      item.WkrID=objEmpaquetadora.NtpSuperviceID
      if(this.validationForm(item))
      item.save();
    },
    validationForm(){
      return true;
    }
  },
};
</script>
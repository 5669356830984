<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      class="text"
      itemvalue="TrnID"
      :disabled="disabled"
      :value="value"
      id="TrnID" itemtext="TrnDescription"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
    >
    </s-select-generic>
  </div>
</template>
<script>

import _sService from "@/services/FreshProduction/ComponentTurn.js";

export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    cultiveID : {
      type: Number,
      default: 0,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },    
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.item = value;
    },
    // cultiveID (){
    //     this.config.params.DefID=this.cultiveID;
    // }
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
    this.config = {
      url: _sService.GetlistTurn(),
      title: this.label,
      params: { requestID: this.$fun.getUserID() },
    };
  },
};
</script>
